import React, { useEffect } from "react";

import { HiChevronRight } from "react-icons/hi";

import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import { formatCreatedAt } from "../../utils/commonUtils";
import {
  fetchLaundryStore,
  fetchLaundryStoreDetail,
  selectLaundryStoreDetail,
} from "../../redux/slices/laundryStoreSlice";

import ModalAddDropOff from "../../components/modals/laundry/ModalAddDropOff";
import ModalAddLaundryItem from "../../components/modals/laundry/ModalAddLaundryItem";

import { ImSpinner2 } from "react-icons/im";
import ModalUpdateLaundryItem from "../../components/modals/laundry/ModalUpdateLaundryItem";
import ModalDeleteLaundryItem from "../../components/modals/laundry/ModalDeleteLaundryItem";
import ModalUpdateDropoff from "../../components/modals/laundry/ModalUpdateDropOff";
import ModalDeleteDropoff from "../../components/modals/laundry/ModalDeleteDropOff";

const LaundryStoreDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const laundryStoreDetail = useSelector(selectLaundryStoreDetail);

  const laundryStores = useSelector(
    (state) => state.laundryStore?.laundryStores
  );

  const status = useSelector(
    (state) => state.laundryStore?.laundryStoreDetailStatus
  );
  const error = useSelector((state) => state.laundryStore?.error);
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  useEffect(() => {
    dispatch(fetchLaundryStoreDetail(id));
    dispatch(fetchLaundryStore());
  }, [dispatch, id]);

  // console.log(laundryStoreDetail);

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  const getLaundryStoreNameById = (laundryStoreId) => {
    const parsedId = parseInt(laundryStoreId, 10);

    const laundrySt = laundryStores.find(
      (laundrySt) => laundrySt.id === parsedId
    );

    return laundrySt ? laundrySt.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Laundry Store Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <Link to="/dashboard/laundry/laundry-store">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">
                Laundry Store
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Store Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {laundryStoreDetail ? (
            <div className="flex mt-8 gap-6">
              <div className="flex flex-col gap-6 w-4/12">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] whitespace-nowrap font-semibold tracking-[0.09px]">
                      Laundry Store Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-gray-500 whitespace-nowrap font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>

                        <p className=" text-base font-semibold break-words w-48 text-right">
                          {laundryStoreDetail.data.laundry.name}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            City
                          </p>
                        </div>

                        <p className=" text-base font-semibold  whitespace-nowrap">
                          {getCityNameById(
                            laundryStoreDetail.data.laundry.city
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            State
                          </p>
                        </div>

                        <p className=" text-base font-semibold  whitespace-nowrap">
                          {getStateNameById(
                            laundryStoreDetail.data.laundry.state
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            Country
                          </p>
                        </div>

                        <p className=" text-base font-semibold  whitespace-nowrap">
                          {getCountryNameById(
                            laundryStoreDetail.data.laundry.country
                          )}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            Created
                          </p>
                        </div>
                        <p className="text-base font-semibold whitespace-nowrap">
                          {formatCreatedAt(
                            laundryStoreDetail.data.laundry.created_at
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                          Updated
                          </p>
                        </div>
                        <p className="text-base font-semibold whitespace-nowrap">
                          {formatCreatedAt(
                            laundryStoreDetail.data.laundry.updated_at
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 w-8/12">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex justify-between">
                      <div>
                        <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Drop Off
                        </h1>
                      </div>

                      <ModalAddDropOff lsId={id} />
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-center text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-50">
                          <tr>
                            <th className="px-4 py-4">No.</th>
                            <th className="px-4 py-4">Name</th>

                            <th className="px-4 py-4">Location</th>
                            <th className="px-4 py-4">Phone</th>

                            <th className="px-4 py-4 whitespace-nowrap">
                              Created Date
                            </th>
                            <th className="px-4 py-4 whitespace-nowrap">
                              Updated Date
                            </th>
                            <th className="px-4 py-4">Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-center">
                          {laundryStoreDetail.data.dropoff.map(
                            (product, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-4">
                                  <div className="flex justify-center items-center">
                                    <p className=" text-base text-center font-semibold ">
                                      {index + 1}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {getLaundryStoreNameById(product.laundry_id)}
                                </td>
                                <td className="px-4 py-2 ">
                                  {product.location}
                                </td>
                                <td className="px-4 py-4 text-center">
                                  {product.phone ? product.phone : "No data"}
                                </td>

                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.created_at)}
                                </td><td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.updated_at)}
                                </td>
                                <td className="px-4 py-4">
                                  <div className="flex justify-center items-center">
                                    <ModalUpdateDropoff
                                      dropoffId={product.id}
                                      laundry_id={product.laundry_id}
                                      phone={product.phone}
                                      location={product.location}
                                    />
                                    <ModalDeleteDropoff
                                      dropoffId={product.id}
                                      location={product.location}
                                      laundry_id={product.laundry_id}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex justify-between">
                      <div>
                        <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Laundry Item
                        </h1>
                      </div>

                      <ModalAddLaundryItem lsId={id} />
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-3 text-center">Name</th>
                            <th className="px-4 py-3 text-center">Dryclean</th>
                            <th className="px-4 py-3 text-center">Wash</th>
                            <th className="px-4 py-3 text-center">Iron</th>
                            <th className="px-4 py-3 text-center ">Starch</th>

                            <th className="px-8 py-3 text-center">
                              Created Date
                            </th>
                            <th className="px-8 py-3 text-center">
                            Updated Date
                            </th>
                            <th className="px-4 py-3 text-center ">Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-left">
                          {laundryStoreDetail.data.laundry_items.map(
                            (product, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {product.name}
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <span className="mr-1">&#8358;</span>
                                  {product.dryclean ? product.dryclean : 0}
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <span className="mr-1">&#8358;</span>
                                  {product.wash ? product.wash : 0}
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <span className="mr-1">&#8358;</span>
                                  {product.iron ? product.iron : 0}
                                </td>

                                <td className="px-4 py-4 text-center">
                                  <span className="mr-1">&#8358;</span>
                                  {product.starch ? product.starch : 0}
                                </td>

                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.created_at)}
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.updated_at)}
                                </td>
                                <td className="px-4 py-4">
                                  <div className=" flex gap-4 justify-center items-center">
                                    <ModalUpdateLaundryItem
                                      item_id={product.id}
                                      name={product.name}
                                      dryclean={product.dryclean}
                                      wash={product.wash}
                                      iron={product.iron}
                                      starch={product.starch}
                                      type={product.type}
                                      laundry_id={id}
                                    />
                                    <ModalDeleteLaundryItem
                                      laundry_id={id}
                                      item_id={product.id}
                                      name={product.name}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          )}
        </div>
      )}
    </div>
  );
};

export default LaundryStoreDetail;
