import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchMarket = createAsyncThunk(
  "market/fetchMarket",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/markets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addMarket = createAsyncThunk(
  "market/addMarket",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/market", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMarket = createAsyncThunk(
  "market/updateMarket",
  async ({ id, formData }, { rejectWithValue }) => {
    
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.put(`/market/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteMarket = createAsyncThunk(
  "market/deleteMarket",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/market/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMarketDetail = createAsyncThunk(
  "marketDetail/fetchMarketDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/market/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const marketSlice = createSlice({
  name: "market",
  initialState: {
    markets: [],
    status: "idle",
    error: null,
    marketDetail: null,
    marketDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarket.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMarket.fulfilled, (state, action) => {
        state.status = "success";
        state.markets = action.payload.data;
      })
      .addCase(fetchMarket.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addMarket.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addMarket.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.markets.unshift(action.payload);
      })
      .addCase(addMarket.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateMarket.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateMarket.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedMarket = action.payload;
        state.markets = state.markets.map((market) =>
          market._id === updatedMarket._id ? updatedMarket : market
        );
        state.success = true;
      })
      .addCase(updateMarket.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteMarket.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteMarket.fulfilled, (state, action) => {
        const deletedMarketId = action.payload;
        state.status = "succeeded";
        state.markets = state.markets.filter(
          (market) => market.id !== deletedMarketId
        );
      })
      .addCase(deleteMarket.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMarketDetail.pending, (state) => {
        state.marketDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchMarketDetail.fulfilled, (state, action) => {
        state.marketDetailStatus = "succeeded";
        state.marketDetail = action.payload;
      })
      .addCase(fetchMarketDetail.rejected, (state, action) => {
        state.marketDetailStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetSuccess } = marketSlice.actions;

export const selectMarketDetail = (state) => state.market?.marketDetail;

export default marketSlice.reducer;
