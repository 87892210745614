import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "../../services/apiService";

export const fetchPharmacy = createAsyncThunk(
  "pharmacy/fetchPharmacy",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/pharmacies", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPharmacyDetail = createAsyncThunk(
  "pharmacyDetail/fetchPharmacyDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/pharmacy/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPharmacy = createAsyncThunk(
  "pharmacy/addPharmacy",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/pharmacy", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePharmacy = createAsyncThunk(
  "pharmacy/deletePharmacy",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      await apiService.delete(`/pharmacy/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePharmacy = createAsyncThunk(
  "pharmacy/updatePharmacy",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(
        `/pharmacy/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "applications/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const pharmacySlice = createSlice({
  name: "pharmacy",
  initialState: {
    pharmacies: [],
    status: "idle",
    error: null,
    pharmacyDetail: null,
    storeDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPharmacy.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPharmacy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pharmacies = action.payload.data;
      })
      .addCase(fetchPharmacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPharmacyDetail.pending, (state) => {
        state.storeDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchPharmacyDetail.fulfilled, (state, action) => {
        state.storeDetailStatus = "succeeded";
        state.pharmacyDetail = action.payload;
      })
      .addCase(fetchPharmacyDetail.rejected, (state, action) => {
        state.storeDetailStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addPharmacy.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addPharmacy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pharmacies.unshift(action.payload);
      })
      .addCase(addPharmacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(deletePharmacy.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deletePharmacy.fulfilled, (state, action) => {
        state.status = "succeeded";
        const deletedPharmacyId = action.payload;
        state.pharmacies = state.pharmacies.filter(
          (pharmacy) => pharmacy.id !== deletedPharmacyId
        );
      })
      .addCase(deletePharmacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(updatePharmacy.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updatePharmacy.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedPharmacy = action.payload;
        state.pharmacies = state.pharmacies.map((pharmacy) =>
          pharmacy.id === updatedPharmacy.id ? updatedPharmacy : pharmacy
        );
      })
      .addCase(updatePharmacy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectPharmacyDetail = (state) => state.pharmacy?.pharmacyDetail;

export default pharmacySlice.reducer;
