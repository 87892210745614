import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchFastFood = createAsyncThunk(
  "fastFood/fetchFastFood",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/foods");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFastFoodDetail = createAsyncThunk(
  "fastFoodDetail/fetchFastFoodDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.get(`/food/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFastFood = createAsyncThunk(
  "fastFood/deleteFastFood",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      await apiService.delete(`/food/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFastFood = createAsyncThunk(
  "fastFood/addFastFood",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/food", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateFastFood = createAsyncThunk(
  "fastFood/updateFastFood",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/food/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const fastFoodSlice = createSlice({
  name: "fastFood",
  initialState: {
    fastFoods: [],
    status: "idle",
    error: null,
    fastFoodDetail: null,
    fastFoodDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFastFood.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFastFood.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fastFoods = action.payload.data;
      })
      .addCase(fetchFastFood.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message
          ? action.error.message
          : "Failed to fetch fastFood data, Network Error";
      })
      .addCase(fetchFastFoodDetail.pending, (state) => {
        state.fastFoodDetailStatus = "loading"; // Update the contribution detail loading status
        state.error = null;
      })
      .addCase(fetchFastFoodDetail.fulfilled, (state, action) => {
        state.fastFoodDetailStatus = "succeeded";
        state.fastFoodDetail = action.payload;
      })
      .addCase(fetchFastFoodDetail.rejected, (state, action) => {
        state.fastFoodDetailStatus = "failed"; // Update the contribution detail status on failure
        state.error = action.error.message || "Failed to fetch contribution detail";
      })
      .addCase(addFastFood.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addFastFood.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fastFoods.unshift(action.payload);
      })
      .addCase(addFastFood.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload
          ? action.payload
          : "Failed to add fastFood";
      })
      .addCase(deleteFastFood.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteFastFood.fulfilled, (state, action) => {
        const deletedFastFoodId = action.payload;
        state.status = "succeeded";
        state.fastFoods = state.fastFoods.filter(
          (fastFood) => fastFood.id !== deletedFastFoodId
        );
      })
      .addCase(deleteFastFood.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete fast Food";
      })
      .addCase(updateFastFood.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateFastFood.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedfood = action.payload;
        state.fastFoods = state.fastFoods.map((food) =>
          food.id === updatedfood.id ? updatedfood : food
        );
        state.success = true;
      })
      .addCase(updateFastFood.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectFastFoodDetail = (state) => state.fastFood?.fastFoodDetail;

export default fastFoodSlice.reducer;
