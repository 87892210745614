import { FaCheck, FaTimes } from "react-icons/fa";

export const getStatusStyles = (status) => {
  switch (status) {
    case "published":
      return "text-green-700 bg-green-100";
    case "Out of stock":
      return "text-red-700 bg-red-100";
    case "In stock":
      return "text-green-700 bg-green-100";
    case "Low stock":
      return "text-orange-700 bg-orange-100";
    case "verified":
      return "text-green-700 bg-green-100";
    case "pending":
      return "text-orange-700 bg-orange-100";
    case "draft":
      return "text-gray-700 bg-gray-100";
    case " Shopper":
      return "text-gray-700 bg-gray-100";
    case "User":
      return "text-slate-700 bg-slate-100";
    case "Admin1":
      return "text-blue-700 bg-blue-100";
    case "Admin2":
      return "text-orange-700 bg-orange-100";
    case "SuperAdmin":
      return "text-green-700 bg-green-100";
    case "Active":
      return "text-green-700 bg-green-100";
    case "Ended":
      return "text-red-700 bg-red-100";
    default:
      return "text-gray-700 bg-gray-100";
  }
};

export const formatCreatedAt = (createdAt) => {
  const date = new Date(createdAt);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
  };
  return date.toLocaleString("en-US", options);
};

export const formatDataTime = (createdAt) => {
  const date = new Date(createdAt);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleString("en-US", options);
};

export const formatTime = (createdAt) => {
  const date = new Date(createdAt);
  const options = {
    // year: "numeric",
    // month: "short",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleString("en-US", options);
};


export const formatTime2 = (createdAt) => {
  const date = new Date(createdAt);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true // This option can be set to true or false based on 12-hour or 24-hour format preference
  };
  return date.toLocaleString("en-US", options);
};



export const formatNumberWithCommas = (number) => {
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    return number;
  }
  return parsedNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};



export const formatNumberWithCommasForUpdate = (number) => {
  if (!number) return '';
  // Remove decimals
  number = number.split('.')[0];
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


// export const formatNumberWithCommas = (number) => {
//   if (!number) return '';
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export const formatNumberWithCommas2 = (number) => {
  return number.toString().replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

export const convertValue = (value) => {
  return value === "1" ? (
    <div className="flex justify-center items-center">
      <FaCheck className="text-green-600" />
    </div>
  ) : value === "0" || value === null ? (
    <div className="flex justify-center items-center">
      <FaTimes className="text-red-600" />
    </div>
  ) : (
    value
  );
};
