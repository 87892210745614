import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../../../redux/slices/productSlice";
import { ImSpinner2 } from "react-icons/im";
import { fetchCategories } from "../../../redux/slices/categorySlice";
import { formatNumberWithCommasForUpdate } from "../../../utils/commonUtils";
import { fetchPharmacyDetail } from "../../../redux/slices/pharmacySlice";

export default function UpdateProductPharmacy({
  id,
  pId,
  name,
  description,
  price,
  category,
  quantity,
  owner_id,
  image,
  city,
  state,
  country,
  type,
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    dispatch(fetchCategories({ id, owner_type: "food" }));
  }, [id, dispatch]);

  useEffect(() => {
    setFormData({
      name,
      description,
      price,
      category,
      quantity,
      owner_id,
      image,
      type,
      city,
      state,
      country,
    });
  }, [
    name,
    description,
    price,
    category,
    quantity,
    owner_id,
    image,
    type,
    city,
    state,
    country,
  ]);

  const categories = useSelector((state) => state.category?.categories);
  const categoryPharmacy = categories?.filter(
    (category) => category?.owner_type === "pharmacy"
  );
  const status = useSelector((state) => state.product?.status);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value.replace(/,/g, "");

    setFormData({
      ...formData,
      [name]: updatedValue,
      quantity: 1500,
    });
  };


  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(updateProduct({ pId, formData }));
      await dispatch(fetchPharmacyDetail(id));
      toast.success(res.payload.message);
    } catch (error) {
      toast.error(error);
    }

    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
      <FaEdit className=" text-base text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Product
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-row gap-6 ">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="name"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name
                        </label>

                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={formData && formData.name}
                          onChange={handleInputChange}
                          placeholder="Type name here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>

                      <div className="flex flex-col gap-2  bg-white rounded-lg shadow">
                        <img
                          src={formData.image}
                          alt={formData.name}
                          className="w-62 h-32 border-2 rounded-lg"
                        />
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="price"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Price
                        </label>
                        <div className="flex items-center">
                          <input
                            name="price"
                            id="price"
                            type="text"
                            value={formatNumberWithCommasForUpdate(
                              formData.price
                            )}
                            onChange={handleInputChange}
                            placeholder="Type price here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Type
                        </label>
                        <select
                          name="type"
                          id="type"
                          value={formData && formData.type}
                          onChange={handleInputChange}
                          required
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option value="" className="text-[#333843]" disabled>
                            Choose type
                          </option>
                          <option value="trending">Trending</option>
                          <option value="general">General</option>
                        </select>
                      </div>

                      {/* <div className="flex flex-col gap-1">
                        <label
                          htmlFor="quantity"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Quantity
                        </label>
                        <div className="flex items-center">
                          <input
                            name="quantity"
                            id="quantity"
                            type="number"
                            value={formData && formData.quantity}
                            onChange={handleInputChange}
                            placeholder="Type Quantity here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div> */}
                      {/* <div className="flex flex-col gap-1">
                        <label
                          htmlFor="owner_id"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Owner Id
                        </label>
                        <div className="flex items-center">
                          <input
                            name="owner_id"
                            id="owner_id"
                            type="number"
                            value={id}
                            readOnly
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div> */}
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="description"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Description
                        </label>
                        <textarea
                          name="description"
                          id="description"
                          cols="20"
                          rows="10"
                          value={formData && formData.description}
                          onChange={handleInputChange}
                          className="bg-[#F9F9FC] border-[#E0E2E7] border-2 text-[14px] text-[#333843] font-normal tracking-[0.07px] p-3 rounded-[8px]"
                          placeholder="Type product description here..."
                        ></textarea>
                      </div>

                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="category"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Select Category
                        </label>

                        <select
                          id="category"
                          name="category"
                          value={formData.category}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] border-2 p-3 text-[14px] rounded-lg bg-[#F9F9FC] w-full border-[#E0E2E7]"
                        >
                          <option value="">Select Category</option>
                          {categoryPharmacy?.map((category) => (
                            <option key={category.id} value={category.name}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* <div className="flex flex-col gap-1">
                        <label
                          htmlFor="owner_type"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Owner Type
                        </label>
                        <div className="flex items-center">
                          <input
                            name="owner_type"
                            id="owner_type"
                            type="text"
                            value="market"
                            readOnly
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div> */}

                      <div className="flex mt-10 gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                          onClick={handleUpdateProduct}
                        >
                          {status === "loading" ? (
                            <>
                              <ImSpinner2 className="animate-spin" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Updating ...
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Update Product
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
