import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

import "react-toastify/dist/ReactToastify.css";
import { formatNumberWithCommas } from "../../utils/commonUtils";

export default function PharmacyProductDetail({
  name,
  description,
  price,
  category,
  color,
  size,
  images,
  city,
  state,
  country,
  created_at,
  updated_at,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <IoEyeOutline className="w-4 h-4 text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-2 max-w-4xl w-full">
              <div className="border-0 rounded-lg p-6 shadow-lg flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-2">
                    Product detail
                  </h1>
                </div>
                <div className="flex mt-8 gap-6">
                  <div className="flex flex-col gap-6 w-6/12">
                    <div className="flex flex-col gap-8 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                      
                        <h1 className="text-[18px] text-[#1A1C21] whitespace-nowrap font-semibold tracking-[0.09px]">
                          Product Information
                        </h1>

                        <div className="flex flex-col gap-[14px]">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                                Name
                              </p>
                            </div>

                            <p className=" text-base w-48 break-words text-right">
                              {name}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                Category
                              </p>
                            </div>

                            <p className=" text-base   whitespace-nowrap">
                              {category}
                            </p>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex gap-2 text-left">
                              <p className="text-[14px]  mb-2 text-[#1A1C21] font-medium tracking-[0.07px]">
                                Description
                              </p>
                            </div>
                            <textarea
                              name=""
                              id=""
                              cols="5"
                              rows="3"
                              value={description}
                              className=" w-full rounded-lg p-2 border-2"
                            ></textarea>
                          </div>
                          {color ? (
                            <div className="flex justify-between items-center">
                              <div className="flex gap-2 items-center">
                                <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                  Color
                                </p>
                              </div>
                              <p className=" text-base   whitespace-nowrap">
                                {color.join(",")}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {size ? (
                            <div className="flex justify-between items-center">
                              <div className="flex gap-2 items-center">
                                <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                  Size
                                </p>
                              </div>
                              <p className=" text-base   whitespace-nowrap">
                                {size.join(",")}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                Price
                              </p>
                            </div>

                            <p className=" text-base   whitespace-nowrap">
                              <span className="mr-1">&#8358;</span>{" "}
                              {formatNumberWithCommas(price)}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                City
                              </p>
                            </div>
                            <p className="text-base whitespace-nowrap">
                              {city}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                State
                              </p>
                            </div>
                            <p className="text-base whitespace-nowrap">
                              {state}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                Country
                              </p>
                            </div>
                            <p className="text-base whitespace-nowrap">
                              {country}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                Created
                              </p>
                            </div>
                            <p className="text-base whitespace-nowrap">
                              {created_at}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                                Updated
                              </p>
                            </div>
                            <p className="text-base  whitespace-nowrap">
                              {updated_at}
                            </p>
                          </div>
                        </div>
                   
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 w-6/12">
                    <div className="flex flex-col gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                        <div className="flex flex-wrap gap-2">
                          {images &&
                            images.map(
                              (imgUrl, index) =>
                                imgUrl && (
                                  <img
                                    key={index}
                                    src={imgUrl}
                                    alt={`${name} ${index + 1}`}
                                    className="ring-2 ring-gray-50 rounded-lg mb-4 max-w-full h-auto object-contain"
                                    style={{ maxWidth: "48%", height: "150px" }}
                                  />
                                )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
