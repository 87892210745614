import React, { useState, useEffect } from "react";

import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

import { formatCreatedAt } from "../../utils/commonUtils";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdmins } from "../../redux/slices/adminSlice";
import { ImSpinner2 } from "react-icons/im";
import ModalVerifyAdmin from "../../components/modals/admin/ModalVerifyAdmin";
import ModalSuspendAdmin from "../../components/modals/admin/ModalSuspendAdmin";
import ModalUnsuspendAdmin from "../../components/modals/admin/ModalUnsuspendAdmin";
import ModalUnverifyAdmin from "../../components/modals/admin/ModalUnverifyAdmin";
import ModalUpdateAdmin from "../../components/modals/admin/ModalUpdateAdmin";
import ModalChangePassword from "../../components/ModalChangePassword";

const Admin = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  const admins = useSelector((state) => state.admin?.admins);

  // console.log(admins);

  const reverseAdmins = admins.slice().reverse();

  // Filter categories based on search term
  const filteredAdmins = reverseAdmins.filter((admin) =>
    search.toLowerCase() === ""
      ? admin
      : admin.firstname.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentAdmins = filteredAdmins.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredAdmins.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const status = useSelector((state) => state.admin?.status);
  const error = useSelector((state) => state.admin?.error);
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Admin ({admins.length})
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-blue-500  font-medium">Admin</p>
            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Admin List</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4 mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <div className="flex items-center gap-4">
          <div>
            <Link to="/dashboard/admin/new-admin">
              <button
                type="button"
                className="flex gap-2 bg-[#1F7BF4] text-[#fff] items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-semibold"
              >
                <FiPlus className=" text-lg font-extrabold" />
                Add Admin
              </button>
            </Link>
          </div>
          <div>
            {/* <button
              type="button"
              className="flex gap-2 text-blue-500 border-2 items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-semibold"
            >
              Change Passwords
            </button> */}
            <ModalChangePassword />
          </div>
        </div>
      </div>

      <div className="bg-white mt-6 rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-left">Full Name</th>

                <th className="px-4 py-3 text-center">Role</th>
                <th className="px-4 py-3 text-center">Country</th>
                <th className="px-4 py-3 text-center">State</th>
                <th className="px-4 py-3 text-center">City</th>
                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Updated</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : (
                currentAdmins?.map((admin, index) => (
                  <tr key={admin.id} className="p-2">
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex flex-col text-left gap-2 ">
                        <Link
                          to={`/dashboard/admin/admin-detail/${admin.id}`}
                          className="text-[#1F7BF4]"
                        >
                          <p className="text-base font-bold">{admin.name}</p>
                        </Link>
                        <p className="text-sm font-semibold text-gray-500">
                          {admin.email}
                        </p>
                        <p className="text-sm font-medium text-gray-500">
                          {admin.phone ? admin.phone : "No Phone Number"}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="p-2 w-48 break-words">
                        <p className="text-gray-500 text-center ">
                          {admin.role ? admin.role : "No role"}
                        </p>
                      </div>
                    </td>
                    <td className="px-2 py-4 text-center font-medium whitespace-nowrap">
                      <p className="p-2">{getCountryNameById(admin.country)}</p>
                    </td>
                    <td className="px-2 py-4 text-center font-medium whitespace-nowrap">
                     <p className="p-2">{getStateNameById(admin.state)}</p> 
                    </td>
                    <td className="px-2 py-4 text-center font-medium whitespace-nowrap">
                      <p className="p-2">{getCityNameById(admin.city)}</p>
                    </td>

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(admin.created_at)}
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(admin.updated_at)}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <Link
                            to={`/dashboard/admin/admin-detail/${admin.id}`}
                          >
                            <IoEyeOutline className="text-lg font-semibold" />
                          </Link>
                        </div>
                        <div>
                          <ModalUpdateAdmin
                            id={admin.id}
                            name={admin.name}
                            email={admin.email}
                            phone={admin.phone}
                            role={admin.role}
                            country={admin.country}
                            state={admin.state}
                            all={admin.all}
                            city={admin.province}
                          />
                        </div>
                        <div className=" flex justify-center items-center">
                          {admin.verify === "1" ? (
                            <ModalUnverifyAdmin
                              id={admin.id}
                              name={admin.name}
                            />
                          ) : (
                            <ModalVerifyAdmin id={admin.id} name={admin.name} />
                          )}
                        </div>
                        <div className=" flex justify-center items-center">
                          {admin.suspend === "1" ? (
                            <ModalUnsuspendAdmin
                              id={admin.id}
                              name={admin.name}
                            />
                          ) : (
                            <ModalSuspendAdmin
                              id={admin.id}
                              name={admin.name}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {filteredAdmins.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
