import React, { useEffect, useState } from "react";

import { HiChevronRight } from "react-icons/hi";

import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";

import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

import {
  fetchStoreDetail,
  selectStoreDetail,
} from "../../redux/slices/storeSlice";

import AddProductStore from "../../components/modals/product/AddProductStore";
import { ImSpinner2 } from "react-icons/im";
import DeleteProductStore from "../../components/modals/product/DeleteProductStore";
import UpdateProductStore from "../../components/modals/product/UpdateProductStore";
import StoreProductDetail from "./StoreProductDetail";
import TransferProductStore from "../../components/modals/product/TransferProductStore";
import StoreDiscountPrice from "../../components/modals/product/StoreDiscountPrice";
import StoreIncrementPrice from "../../components/modals/product/StoreIncrementPrice";

const StoreDetail = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();
  const storeDetail = useSelector(selectStoreDetail);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  useEffect(() => {
    dispatch(fetchStoreDetail(id));
  }, [dispatch, id]);

  const status = useSelector((state) => state.store?.storeDetailStatus);
  const error = useSelector((state) => state.store?.error);

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const storeProducts = storeDetail?.data?.products?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(
    storeDetail?.data?.products?.length / itemsPerPage
  );

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, productId) => {
    const isChecked = e.target.checked;

    setSelectedProductIds((prevSelectedIds) => {
      if (isChecked) {
        // If checkbox is checked, add the productId to the selectedProductIds array
        return [...prevSelectedIds, productId];
      } else {
        // If checkbox is unchecked, remove the productId from the selectedProductIds array
        return prevSelectedIds.filter((id) => id !== productId);
      }
    });
  };

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setSelectAll(isChecked);

    if (isChecked) {
      const allProductIds = storeDetail?.data?.products?.map(
        (product) => product.id
      );
      setSelectedProductIds(allProductIds);
    } else {
      setSelectedProductIds([]);
    }
  };

  const clearSelectedProductIds = () => {
    setSelectedProductIds([]);
    setSelectAll(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Store Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4] font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />

            <Link to="/dashboard/shopping/stores">
              <p className="text-[14px] text-[#1F7BF4] font-medium">Stores</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Store Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {storeDetail ? (
            <>
              <div className="flex my-4  gap-6">
                <div className="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                        Store Information
                      </h1>

                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                              Name
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {storeDetail.data.Store.name}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Landmark
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {storeDetail.data.Store.landmark}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Location
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {storeDetail.data.Store.location}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Status
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {storeDetail.data.Store.type}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Start Date
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {formatCreatedAt(storeDetail.data.Store.created_at)}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Updated Date
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {formatCreatedAt(storeDetail.data.Store.updated_at)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Country
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {getCountryNameById(storeDetail.data.Store.country)}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              State
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {getStateNameById(storeDetail.data.Store.state)}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              City
                            </p>
                          </div>
                          <p className="text-base font-semibold whitespace-nowrap">
                            {getCityNameById(storeDetail.data.Store.province)}
                          </p>
                        </div>

                        <div className="flex justify-between gap-4">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Note
                            </p>
                          </div>
                          <textarea
                            name=""
                            id=""
                            cols="10"
                            rows="5"
                            value={storeDetail.data.Store.note}
                            className=" w-1/2 text-[#1A1C21] text-base border-2 rounded-lg p-2 tracking-[0.07px]"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex justify-between">
                      <div>
                        <h1 className="text-lg text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Products
                        </h1>
                      </div>

                      <div className=" flex justify-between items-center gap-4">
                        <AddProductStore
                          storeId={id}
                          country={storeDetail.data.Store.country}
                          city={storeDetail.data.Store.province}
                          state={storeDetail.data.Store.state}
                        />
                        {storeDetail.data.products.length === 0 ? (
                          ""
                        ) : (
                          <TransferProductStore owner_id={id} />
                        )}
                        {storeDetail.data.products.length === 0 ? (
                          ""
                        ) : (
                          <StoreDiscountPrice
                            storeId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                          />
                        )}

                        {storeDetail.data.products.length === 0 ? (
                          ""
                        ) : (
                          <StoreIncrementPrice
                            storeId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-4 text-center">No.</th>
                            <th className="px-12 py-4 text-center">Image</th>
                            <th className="px-4 py-4 text-center">Name</th>
                            <th className="px-4 py-4 text-center">Category</th>
                            <th className="px-4 py-4 text-center">
                              Description
                            </th>
                            <th className="px-4 py-4 text-center">Type</th>
                            <th className="px-4 py-4 text-center ">Price</th>
                            <th className="px-8 py-4 text-center">
                              Created Date
                            </th>
                            <th className="px-8 py-4 text-center">
                              Updated Date
                            </th>
                            <th className="px-2 py-4 text-center">
                              <div className=" flex justify-center items-center gap-2">
                                <h1>Action</h1>
                                {storeDetail.data.products.length === 0 ? (
                                  ""
                                ) : (
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleHeaderCheckboxChange}
                                    className="w-4 h-4"
                                  />
                                )}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-left">
                          {status === "loading" ? (
                            <tr>
                              <td colSpan="12" className="text-center py-4">
                                <div className="flex justify-center items-center">
                                  <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                                </div>
                              </td>
                            </tr>
                          ) : storeProducts?.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Data
                              </td>
                            </tr>
                          ) : (
                            storeProducts.map((product, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-4">
                                  <div className="flex justify-center items-center">
                                    <p className=" text-base text-center font-semibold ">
                                      {indexOfFirstItem + index + 1}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-4 py-4">
                                  <div className="flex justify-center p-2 items-center">
                                    <img
                                      src={
                                        product.image &&
                                        product.image.length > 0
                                          ? product.image[0]
                                          : "default-image-url.jpg"
                                      }
                                      alt="product"
                                      className="w-24 h-24 object-cover rounded-lg"
                                    />
                                  </div>
                                </td>
                                <td className="px-4 py-4">
                                  <div className="p-2">
                                    <p className="text-gray-500 text-center w-48 breake-words ">
                                      {product.name}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {product.category}
                                </td>
                                <td className="px-14 py-4">
                                  <p className="w-48 break-words text-center">
                                    {product.description}
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-center">
                                  {product.type}
                                </td>

                                <td className="px-4 py-4 text-center">
                                  <p>
                                    <span className="mr-1">&#8358;</span>
                                    {formatNumberWithCommas(product.price)}
                                  </p>
                                </td>

                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.created_at)}
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.updated_at)}
                                </td>
                                <td>
                                  <div className="flex gap-[8px] justify-center items-center">
                                    <div>
                                      <StoreProductDetail
                                        images={product.image}
                                        category={product.category}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        color={product.color}
                                        size={product.size}
                                        country={getCountryNameById(
                                          product.country
                                        )}
                                        city={getCityNameById(product.city)}
                                        state={getStateNameById(product.state)}
                                        created_at={formatCreatedAt(
                                          product.created_at
                                        )}
                                        updated_at={formatCreatedAt(
                                          product.updated_at
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <UpdateProductStore
                                        id={id}
                                        pId={product.id}
                                        image={product.image[0]}
                                        category={product.category}
                                        name={product.name}
                                        owner_id={product.owner_id}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        country={product.country}
                                        city={product.city}
                                        state={product.state}
                                      />
                                    </div>

                                    <div>
                                      <DeleteProductStore
                                        id={id}
                                        pId={product.id}
                                        image={product.image[0]}
                                        category={product.category}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                      />
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        value={product.id}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, product.id)
                                        }
                                        className="w-4 h-4"
                                        checked={selectedProductIds?.includes(
                                          product.id
                                        )}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex justify-between px-[24px] py-[15px] items-center">
                      <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
                        Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
                        {storeDetail.data.products?.length}
                      </p>

                      <div className="flex gap-[8px]">
                        <button
                          onClick={prevPage}
                          disabled={currentPage === 1}
                          className={`px-4 py-2 rounded-md ${
                            currentPage === 1
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                        >
                          Previous
                        </button>

                        <button
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                          className={`px-4 py-2 rounded-md ${
                            currentPage === totalPages
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          )}
        </div>
      )}
    </div>
  );
};

export default StoreDetail;
